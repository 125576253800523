import { useCallback, useContext, useEffect, useRef, useState } from "react";
import "./chatList.scss";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
  // MdAttachFile,
} from "react-icons/md";
import { HiMail } from "react-icons/hi";

import DEFAULTPIC from "../../media/images/default.jpg";
import ONLINE from "../../media/images/online.png";

import EMOJI from "../../media/icons/blush.png";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";

// Toast notifications
// import { toast } from "sonner";

import messageSent from "../../media/alerts/message-sent.wav";
import messageReceived from "../../media/alerts/new-message.wav";
import { SocketContext } from "../../context/SocketContext";
import { makeRequest, makeRequestjwt } from "../../axios";

const Messaging = () => {
  const { loggedInUser, authToken, userDetails } = useContext(AuthContext);
  const { socketConnect, onlineUsers, disconnectedUsers } =
    useContext(SocketContext);

  // console.log(onlineUsers);

  const sentAlert = new Audio(messageSent);

  const [openChat, setOpenChat] = useState(false);

  const toggleChatBody = () => {
    setOpenChat(!openChat);
  };

  const fetchChatList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/chats/getChatList/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      // console.error("Error fetching chats:", error);
      throw new Error(error.message);
    }
  };

  const { data: chatList } = useQuery({
    queryKey: ["chatList", userDetails?.userId],
    queryFn: fetchChatList,
    enabled: !!userDetails?.userId,
  });

  // Current Chat
  const [currentChat, setCurrentChat] = useState(null);

  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState([]);

  const setSeen = useCallback(async () => {
    try {
      await makeRequest.put(
        "chats/setSeen",
        { chatId: currentChat?.chatId },
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
    } catch (error) {
      console.error(error);
    }
  }, [currentChat, authToken]);

  const openConversation = async (user) => {
    // console.log(currentChat)

    setCurrentChat(user);

    // Set received messages as seen
    setSeen();
  };
  // console.log("socketConnect:", socketConnect);
  useEffect(() => {
    const receivedAlert = new Audio(messageReceived);
    const handleMessage = (data) => {
      receivedAlert.play();

      setArrivalMessage({
        sender: data?.senderId,
        chatMsg: data?.messageText,
        chatImg: data?.chatImg,
        date: Date.now(),
      });

      setSeen();
    };

    if (socketConnect) {
      socketConnect.on("getMessage", handleMessage);
    }

    return () => {
      socketConnect?.off("getMessage", handleMessage);
    };
  }, [socketConnect, setSeen]);

  // console.log(arrivalMessage);

  useEffect(() => {
    if (arrivalMessage && arrivalMessage.sender === currentChat?.userId) {
      setMessages((prev) => [...prev, arrivalMessage]);
    }
  }, [arrivalMessage, currentChat]);

  // Make the screen always scroll to the bottom
  const scrollRef = useRef();
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Fetch mutual messages
  const fetchMessages = useCallback(async () => {
    if (!currentChat) return;
    // console.log(currentChat.chatId)

    try {
      const res = await makeRequestjwt.get(
        `${process.env.REACT_APP_URL}/chats/getMutualMessages/${currentChat?.chatId}`,
        {
          withCredentials: true,
        }
      );
      setMessages(res.data);
      return res.data;
    } catch (error) {
      console.error("Error fetching chats:", error);
      throw new Error(error.message);
    }
  }, [currentChat]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  const [messageText, setMessageText] = useState("");

  // console.log(messageText?.length);
  const [chatImage, setChatImage] = useState(null);
  const resetForm = () => {
    setChatImage(null);
    setMessageText("");
  };

  useEffect(() => {
    if (messageText) {
      socketConnect?.emit("typing", {
        senderId: userDetails?.userId,
        receiverId: currentChat?.userId,
        isTyping: true,
      });
    } else {
      socketConnect?.emit("typing", {
        senderId: userDetails?.userId,
        receiverId: currentChat?.userId,
        isTyping: false,
      });

      socketConnect?.emit("notTyping", {
        senderId: userDetails?.userId,
      });
    }
  }, [socketConnect, messageText, currentChat, userDetails]);

  const sendMessage = async (e) => {
    e.preventDefault();

    // console.log("Here")

    sentAlert.play();

    let imgUrl = "";
    if (chatImage) {
      try {
        const formData = new FormData();
        formData.append("messageImage", chatImage);
        const res = await makeRequest.post(`chats/addMessageImage`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`,
          },
        });
        imgUrl = res.data;
      } catch (error) {
        console.error(error);
      }
    }

    socketConnect?.emit("sendMessage", {
      senderId: userDetails?.userId,
      receiverId: currentChat.userId,
      messageText,
      chatImg: imgUrl,
    });

    const messageContent = {
      chatMsg: messageText,
      chatImg: imgUrl,
      chatId: currentChat?.chatId,
    };

    try {
      axios.defaults.withCredentials = true;
      const res = await makeRequestjwt.post(
        `${process.env.REACT_APP_URL}/chats/sendMessage/${currentChat.userId}`,
        messageContent
      );

      // fetchMessages();
      setMessages([...messages, res.data]);
      resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  // Show user's last seen
  const user = disconnectedUsers?.find(
    (user) => user.userId === currentChat?.userId
  );
  const lastSeen = user ? user.lastSeen : null;
  // console.log("lastSeen:", lastSeen);

  const [typing, setTyping] = useState(false);
  const [typingUsers, setTypingUsers] = useState([]);

  // console.log("Typing:", typing);

  useEffect(() => {
    const Typing = (data) => {
      setTyping(data.text);
    };

    if (socketConnect) {
      socketConnect.on("userTyping", Typing);
    }

    return () => {
      socketConnect?.off("userTyping", Typing);
    };
  }, [socketConnect]);

  // Get users that are typing
  useEffect(() => {
    const TypingUsers = (users) => {
      // console.log("Received Typing Users:", users);
      const typingUserIds = users.map((user) => user.senderId);
      setTypingUsers(typingUserIds);
    };

    if (socketConnect) {
      socketConnect.on("getTypingUsers", TypingUsers);
    }

    return () => {
      if (socketConnect) {
        socketConnect.off("getTypingUsers", TypingUsers);
      }
    };
  }, [socketConnect]);

  return (
    <div
      className="messaging"
      style={{
        height: openChat ? "calc(100vh - 9em)" : "calc(100vh - 34.8em)",
        transition: "0.5s ease",
      }}
    >
      <div className="messaging-header">
        <div className="messaging-header-left">
          <p>Messages</p>
        </div>
        <div className="messaging-header-right">
          <div className="notification">
            <HiMail className="icon" />
            {arrivalMessage?.length > 0 && (
              <div className="notification-count">{arrivalMessage.length}</div>
            )}
          </div>
          <div className="switch">
            {!openChat ? (
              <MdKeyboardDoubleArrowUp
                className="icon"
                onClick={toggleChatBody}
              />
            ) : (
              <MdKeyboardDoubleArrowDown
                className="icon"
                onClick={toggleChatBody}
              />
            )}
          </div>
        </div>
      </div>
      <div className="messaging-body">
        <div className="messaging-body-left">
          <div className="searchUser">
            <input type="text" placeholder="Search user" />
          </div>
          {chatList?.length > 0
            ? chatList.map((user, index) => (
                <div
                  className="useList"
                  key={index}
                  // onClick={() => setCurrentChat(user)}
                  onClick={() => openConversation(user)}
                >
                  <div className="useList-left">
                    {user.userImage ? (
                      <img
                        src={`${process.env.REACT_APP_URL}/images/users/profileImages/${user.userImage}`}
                        alt=""
                      />
                    ) : (
                      <img src={DEFAULTPIC} alt="" />
                    )}
                    {onlineUsers?.includes(user.userId) ? (
                      <div className="onlineStatus">
                        <img src={ONLINE} alt="onlineImg" />
                      </div>
                    ) : (
                      <div className="offlineStatus"></div>
                    )}
                  </div>
                  <div className="useList-right">
                    <div className="userName">
                      {user.fullName ? user.fullName : user.username}
                    </div>
                    <div className="lastMessage">
                      {typingUsers?.includes(user?.userId) ? (
                        <p>Typing...</p>
                      ) : !typingUsers?.includes(user?.userId) ||
                        typing?.senderId !== user?.userId ? (
                        <div>
                          {user?.lastMessageSender === loggedInUser.userId
                            ? "You:"
                            : ""}{" "}
                          {user.lastMessage?.length > 16
                            ? user.lastMessage?.slice(0, 16) + "..."
                            : user?.lastMessage}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))
            : "You have no chat history with anyone!"}
        </div>
        <div className="messaging-body-right">
          {currentChat ? (
            <>
              <div className="incomingUser">
                <img
                  src={
                    currentChat?.userImage !== null
                      ? `${process.env.REACT_APP_URL}/images/users/profileImages/${currentChat?.userImage}`
                      : DEFAULTPIC
                  }
                  alt=""
                />
                <Link to={`/profile/${currentChat?.username}`} className="name">
                  {currentChat?.fullName
                    ? currentChat?.fullName
                    : currentChat?.username}

                  {typingUsers?.includes(currentChat?.userId) ? (
                    <p> Typing...</p>
                  ) : !typingUsers?.includes(currentChat?.userId) ||
                    typing?.senderId !== currentChat?.userId ? (
                    <p>
                      {onlineUsers.includes(currentChat?.userId) ? (
                        <div className="online">Online</div>
                      ) : (
                        <div className="online">
                          {lastSeen
                            ? `Last seen: ${moment(lastSeen).fromNow()}`
                            : `Last seen: ${moment(
                                currentChat?.lastSeen
                              ).fromNow()}`}
                        </div>
                      )}
                    </p>
                  ) : null}
                </Link>
              </div>
              <div className="chat-screen">
                {messages?.length > 0 ? (
                  messages?.map((chatData, index) => (
                    <div
                      ref={scrollRef}
                      className={`msgs ${
                        chatData?.senderId === userDetails?.userId
                          ? "outgoing-msg"
                          : "incoming-msg"
                      }`}
                      key={index}
                    >
                      <div className="msg-details">
                        {chatData?.chatImg && (
                          <img
                            src={`${process.env.REACT_APP_URL}/images/chats/${chatData?.chatImg}`}
                            alt="chatimage"
                          />
                        )}
                        {chatData?.chatMsg && <p>{chatData?.chatMsg}</p>}
                        <small>{moment(chatData?.date).format("LLL")}</small>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="startChat">
                    Send a message to start a conversation
                  </div>
                )}
              </div>
              <div className="inputForm">
                <form encType="multipart/form-data">
                  <div className="left">
                    <textarea
                      cols="30"
                      rows="1"
                      placeholder="Type a message..."
                      value={messageText}
                      onChange={(e) => setMessageText(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="right">
                    {/* <label htmlFor="addFile">
                      <MdAttachFile className="icon" />
                    </label> */}
                    <input
                      type="file"
                      name="chatImage"
                      id="addFile"
                      onChange={(e) => setChatImage(e.target.files[0])}
                    />

                    <img src={EMOJI} alt="" />
                    <button
                      onClick={sendMessage}
                      type="submit"
                      disabled={!messageText.trim() && !chatImage}
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <div className="emptyChat">
              <p>Click a user to open a conversation</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Messaging;
